export const ASSIGNMENT_TYPES = {
  INDIVIDUAL: 'Individual',
  GROUP: 'Group'
};

export const STATUSES = {
  UPCOMING: 'Upcoming',
  OPEN: 'Open',
  IN_PROGRESS: 'In Progress',
  WRITING_REPORT: 'Writing Report',
  READY_FOR_REVIEW: 'Ready for Review',
  READY: 'Ready',
  COMPLETED: 'Completed',
  CLOSED: 'Closed',
  GROUP: 'Group',
  SUBMITTED: 'Submitted',
  INDIVIDUAL: 'Individual',
  DRAFT: 'Draft'
};

export const STATUS_TITLES = {
  [STATUSES.UPCOMING]: 'Upcoming',
  [STATUSES.OPEN]: 'Open',
  [STATUSES.IN_PROGRESS]: 'In Progress',
  [STATUSES.WRITING_REPORT]: 'Reporting',
  [STATUSES.READY]: 'Ready',
  [STATUSES.READY_FOR_REVIEW]: 'Ready for Review',
  [STATUSES.COMPLETED]: 'Completed',
  [STATUSES.CLOSED]: 'Closed',
  [STATUSES.GROUP]: 'Group',
  [STATUSES.INDIVIDUAL]: 'Individual',
  [STATUSES.SUBMITTED]: 'Submitted',
  [STATUSES.DRAFT]: 'Draft'
};

export const STATUS_CLASS_NAMES = {
  [STATUSES.UPCOMING]: 'upcoming',
  [STATUSES.OPEN]: 'open',
  [STATUSES.READY_FOR_REVIEW]: 'ready-for-review',
  [STATUSES.IN_PROGRESS]: 'in-progress',
  [STATUSES.WRITING_REPORT]: 'reporting',
  [STATUSES.READY]: 'ready',
  [STATUSES.COMPLETED]: 'completed',
  [STATUSES.CLOSED]: 'closed',
  [STATUSES.GROUP]: 'group',
  [STATUSES.SUBMITTED]: 'submitted',
  [STATUSES.INDIVIDUAL]: 'individual',
  [STATUSES.DRAFT]: 'draft'
};

export const PROGRESS_COLORS = {
  [STATUSES.COMPLETED]: '#75D180',
  [STATUSES.READY_FOR_REVIEW]: '#4d80ff',
  [STATUSES.WRITING_REPORT]: '#3AC6E0',
  [STATUSES.IN_PROGRESS]: '#FC2472',
  [STATUSES.OPEN]: '#FCCC24'
};

export const ROLES = {
  STUDENT: 'student',
  TEACHER: 'teacher'
};

export const DATE_FORMATS = {
  DDMMYYHHmm: 'DD/MM/YYYY, HH:mm'
};

export const STYLE_VARS = {
  PRIMARY_COLOR: '--primary-color',
  SECONDARY_COLOR: '--secondary-color',
  BACKGROUND_COLOR: '--background-color',
  ACTIVE_FRAME_BORDER_COLOR: '--active-frame-border-color',
  FONT_FAMILY: '--font-family',

  CLOSE_ICON: '--close-icon',
  RESTART_ICON: '--restart-icon',
  LANGUAGE_ICON: '--language-icon',
  BACKPACK_ICON: '--backpack-icon',
  JOURNAL_ICON: '--journal-icon',
  NEXT_ICON: '--next-icon',
  BACK_ICON: '--back-icon',

  CLOSE_ICON_COLOR: '--close-icon-color',
  RESTART_ICON_COLOR: '--restart-icon-color',
  BACKPACK_ICON_COLOR: '--backpack-icon-color',
  JOURNAL_ICON_COLOR: '--journal-icon_color',
  NEXT_ICON_COLOR: '--next-icon-color',
  BACK_ICON_COLOR: '--back-icon-color',
  KEN_BURNS_ZOOM_IN: '--ken-burns-zoom-in',
  FLOATING_ICON: '--floating-icon',
  FLOATING_ICON_COLOR: '--floating-icon-color'
};

export const defaultFCoords = {
  'left-bottom': [
    { x: 0, y: 0 },
    { x: 100, y: 0 },
    { x: 100, y: 75 },
    { x: 20, y: 75 }, //
    { x: 0, y: 100 }, //
    { x: 0, y: 75 }
  ],
  'right-bottom': [
    { x: 0, y: 0 },
    { x: 100, y: 0 },
    { x: 100, y: 75 },
    { x: 100, y: 100 }, //
    { x: 80, y: 75 }, //
    { x: 0, y: 75 }
  ],
  'center-bottom': [
    { x: 0, y: 0 },
    { x: 100, y: 0 },
    { x: 100, y: 75 },
    { x: 60, y: 75 }, //
    { x: 50, y: 100 }, //
    { x: 40, y: 75 }, //
    { x: 0, y: 75 }
  ],
  'left-top': [
    { x: 0, y: 25 },
    { x: 0, y: 0 }, //
    { x: 20, y: 25 }, //
    { x: 100, y: 25 },
    { x: 100, y: 100 },
    { x: 0, y: 100 }
  ],
  'right-top': [
    { x: 0, y: 25 },
    { x: 75, y: 25 }, //
    { x: 100, y: 0 }, //
    { x: 100, y: 25 },
    { x: 100, y: 100 },
    { x: 0, y: 100 }
  ],
  'center-top': [
    { x: 0, y: 25 },
    { x: 40, y: 25 }, //
    { x: 50, y: 0 }, //
    { x: 60, y: 25 }, //
    { x: 100, y: 25 },
    { x: 100, y: 100 },
    { x: 0, y: 100 }
  ]
};

export const controlSpeechCoords = {
  'left-bottom': [2, 5],
  'right-bottom': [2, 5],
  'center-bottom': [2, 6],
  'left-top': [0, 3],
  'right-top': [0, 3],
  'center-top': [0, 4]
};

export const DESKTOP_TYPE = 'desktop';
export const MOBILE_TYPE = 'mobile';
export const TABLET_TYPE = 'tablet';

export const FRAME_WIDTH_LANDSCAPE_TRANSFORM_COEF = 7.98;
export const FRAME_WIDTH_PORTRAIT_TRANSFORM_COEF = 3.84;

export const FRAME_HEIGHT_TRANSFORM_COEF = 3.99;

export const ENLARGE_COEFFICIENT = 1.08;

export const SCALE_FRAME = 1.05;
